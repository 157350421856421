.Main {
  display: -webkit-flex;
  display: flex;
  background-image: url("background3.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.Nav {
  display: -webkit-flex;
  display: flex;
  position: fixed;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 50px;
  height: 150%;
  background-color: #333;
  text-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.Nav a {
  color: whitesmoke;
}

.NavLinks {
  padding-top: 2em;
}

.MainBody {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  /* -webkit-justify-content: space-evenly;
  justify-content: space-evenly; */
  -webkit-flex-direction: column;
  flex-direction: column;
  text-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-left: 50px;
}

.Title {
  width: 100%;
  padding-top: 10%;
  padding-bottom: 15%;
  color: #333;
  text-shadow: 1px 1px whitesmoke;
}

.AboutT {
  background-color: whitesmoke;
  color: #333;
  width: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  vertical-align: middle;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-top: 8%;
  padding-bottom: 8%;
}
.AboutP {
  padding-left: 3%;
  padding-right: 3%;
}
.Recent {
  background-color: whitesmoke;
  width: 100%;
  color: #333;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  vertical-align: middle;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-bottom: 5%;
  padding-top: 5%;
}

.RecentH {
  padding-left: 3%;
  padding-right: 3%;
}

.ProjectItems {
  width: 100%;
  background-color: whitesmoke;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  vertical-align: middle;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.Contact {
  background-color: whitesmoke;
  color: #333;
  width: 100%;
}

h2 {
  margin: 0;
}

.Example {
  margin: 4em;
  width: 10em;
}

h4 {
  color: #333;
}

.ProItems {
  width: 100%;
  background-color: whitesmoke;
}
a {
  color: inherit;
  text-decoration: none;
}
